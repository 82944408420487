import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';

const FixedWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: `var(--mui-sunrise-light-info-main)`,
  color: `var(--mui-sunrise-light-info-onMain)`,
  width: '100%',
  textAlign: 'center',
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 100,
  },
}));

export type PromotionBannerProps = {
  promotionCode?: string;
};

export const PromotionBanner = ({
  promotionCode,
}: PromotionBannerProps): JSX.Element => {
  const { t } = useTranslation();
  if (!promotionCode) {
    return <></>;
  }

  return (
    <FixedWrapper>
      {t('banner.promoCodeApplied', { promoCode: promotionCode })}
    </FixedWrapper>
  );
};
