import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { AnalyticsButton } from '@summer-health/ui/care-analytics';
import { ContentfulRichText } from '@summer-health/ui/contentful-rich-text';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ContentfulInterstitialPage } from './contentful-interstitial-page.types';

export type InterstitialCardProps = {
  content: ContentfulInterstitialPage;
  navigateUrlOverride?: string;
  // TODO refactor as something more systematic
  // like using mustache templates
  variables?: Map<string, string>;
};

const LanguageSwitcher = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const newLocale = i18n.language === 'en' ? 'es' : 'en';
  const router = useRouter();

  return (
    <Button
      key="language-switcher"
      fullWidth
      color="quaternary"
      onClick={() => {
        const { pathname, asPath, query } = router;
        router
          .push({ pathname, query }, asPath, {
            locale: newLocale,
          })
          .then(() => {
            // Somehow page didn't refresh. had to reload it.
            router.reload();
          });
      }}
    >
      {t('menu.switchLanguage')}
    </Button>
  );
};

export const InterstitialCard = ({
  content,
  navigateUrlOverride,
  variables,
}: InterstitialCardProps): JSX.Element => {
  const { name, header, body, buttonsCollection } = content;
  return (
    <Card variant="standard">
      <CardHeader
        title={
          <>
            <Typography variant="h2" component="h1" paddingBottom={0}>
              {header.replace(
                '%child_names%',
                variables?.get('child_names') || '',
              )}
            </Typography>
          </>
        }
      />
      <CardContent>
        <ContentfulRichText content={body.json} />
        {buttonsCollection?.items?.length > 0 && (
          <Divider
            variant="standard"
            sx={(theme): object => ({
              marginTop: theme.spacing(2),
            })}
          />
        )}
      </CardContent>
      {buttonsCollection?.items?.length > 0 && (
        <>
          <CardActions>
            <Stack spacing={1.5} width="100%">
              {buttonsCollection.items.map((button) => (
                <AnalyticsButton
                  key={button.displayText}
                  fullWidth
                  color={button.color}
                  cardId={`interstitial:${name}`}
                  pageSection={`interstitial:${name}`}
                  to={
                    navigateUrlOverride ||
                    (button.url ?? '').replace(
                      '%care_number%',
                      variables?.get('care_number') || '',
                    ) + window.location.search
                  }
                >
                  {button.displayText}
                </AnalyticsButton>
              ))}
              {variables?.get('hasLanguageSwitcher') === 'true' && (
                <LanguageSwitcher />
              )}
            </Stack>
          </CardActions>
        </>
      )}
    </Card>
  );
};
